<template>
  <div id="app" class="login">
    <div class="login-container">
      <div class="formmessage">
        <div class="message">
          <img class="logo" src="./assets/logo.png" alt="logo potencie" />
          <p>
            La red que
            <br />necesitas
            <br />para tu
            <span class="marcado">éxito</span>
          </p>
        </div>
      </div>

      <div class="from-container">
        <div class="formbox container">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
    <div class="second-page">
      <iframe id="iframe" src="https://disnovo.com/cmpgs/2-gg/?origin=45f07200888041d7be85a0b9cf4eb084" allowfullscreen=""  frameborder="0" width="100%"  scrolling="no" >
      </iframe>
    </div>
        <div style="width: 100%;height: 10rem;background: #0e1a35;color: white;position: absolute;text-align: center;top: 755vh;">
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.disnovo.potencie21&hl=es_PE&gl=US"  target="_blank">
              <img src="https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png" alt="" style="width:10rem;height:4rem">
            </a>
          </div>
          <div style="font-size:1.3rem">Disnovo Lab 2021 Todos los derechos reservados.</div>
          <div style="font-size:1.3rem; font-weight:bold">Powered by Potencie</div>
          <a target="_blank" href="https://potencie.com/privacidad/" style="text-decoration: none; color: white; font-size: 1rem; margin-bottom: 1rem; margin-right:0.5rem" t_blas> Política de Privacidad </a>
          <a target="_blank" href="https://potencie.com/tyc/" style="text-decoration: none; color: white; font-size: 1rem; margin-bottom: 1rem;"> Términos y Condiciones</a>
        </div>
    <p-modal :active="fromAndroid" :canCancel="true">
      <section class="card">
        <div class="card-content has-center">
          <p>
            <strong>Potencie es mejor en app</strong>
            <br />Más efectos solo en el app.
          </p>

          <button class="button is-brand" @click="openInApp">Abrir en aplicación</button>
          <br />
          <br />
          <button class="button" @click="openInWeb">Seguir en la web</button>
        </div>
      </section>
    </p-modal>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      fromAndroid: false,
    };
  },
  methods: {
    openInApp() {
      /*let appUri =
        'intent:#Intent;action=com.disnovo.potencielink.open;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;';*/

      let appUri =
        'intent:#Intent;action=com.disnovo.potencielink.open;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;';

      if (this.$route.meta['view']) {
        appUri += 'S.view=' + this.$route.meta['view'] + ';';
      }

      appUri +=
        'S.browser_fallback_url=http://play.google.com/store/apps/details?id=com.disnovo.potencie;end';
      //appUri += 'end';

      location.href = appUri;
    },
    openInWeb() {
      this.fromAndroid = false;
    },
  },
  mounted() {
    const isAndroid = navigator.userAgent.match(/Android/i);
    if (isAndroid) {
      this.fromAndroid = true;
    }
  },
};
</script>

<style lang="scss">
@import '~potencie-core/src/assets/style/main.scss';
.second-page{
  display: flex;
  width: 100%;
  height:660vh ;
  background-color: white;
}

body {
  --brand-color: #0000ad;
}

#app.login > .login-container {
  background-color: $white;
  background-size: cover;

  @media (orientation: landscape) {
    background-image: url('~@/assets/landspace.png');
  }

  @media (orientation: portrait) {
    background-image: url('~@/assets/portrait.png');
  }

  @media #{$mobile},#{$tablet},#{$bigtablet} {
    display: flex;
  }
}

.login-container {
  display: flex;

  @media #{$mobile},#{$tablet},#{$bigtablet} {
    flex-direction: column;
    margin: auto;
    width: 100%;
  }

  @media #{$desktop} {
    min-height: 100vh;
    flex-direction: row-reverse;
  }
}

.from-container {
  height: 100%;

  @media #{$mobile} {
    margin: auto;
    width: 100%;
  }

  @media #{$tablet} {
    margin: auto;
    width: 75%;
  }

  @media #{$bigtablet} {
    margin: auto;
    width: 45rem;
  }

  @media #{$desktop} {
    width: 50%;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
}

.formmessage {
  @media #{$mobile},#{$tablet},#{$bigtablet} {
    width: 100%;

    & > .message {
      text-align: center;

      & > p {
        display: none;
      }
    }
  }

  @media #{$desktop} {
    width: 50%;
    display: flex;
    padding: 2rem 4rem;
    box-sizing: border-box;

    & > .message {
      margin: auto;
      width: 100%;
      max-width: 35rem;
      text-align: right;
      & > p {
        font-size: 5rem;
        font-weight: bold;
        color: $white;
        margin: 1rem 0;

        & > .marcado {
          display: inline-block;
          position: relative;
          &::after {
            display: block;
            content: '';
            position: absolute;
            background-color: $white;
            height: 0.75rem;
            width: 100%;
          }
        }
      }
    }
  }
}

.formbox {
  label {
    color: var(--brand-color);
  }

  @media #{$mobile},#{$tablet},#{$bigtablet},#{$desktop} {
    width: 100%;
  }

  @media #{$desktop} {
    max-width: 50rem;
  }
}

.from-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

@media #{$mobile},#{$tablet},#{$bigtablet} {
  .transparent-card {
    background: transparent;
    color: $white;

    & .card-content {
      border-bottom: none;
    }

    & input {
      color: $white;
    }

    & label {
      color: $white;
    }

    & a {
      color: $white;
    }
  }
}

@media #{$desktop} {
  .transparent-card {
    padding: 6rem 7rem;

    & > .card-content {
      border-bottom: 0px;
    }
  }
}

.logo {
  width: 80%;
  max-width: 18rem;
}
</style>
