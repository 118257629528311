<template>
  <section class="card transparent-card">
    <div class="card-content">
      <h2 class="has-center">{{ $t('CREATE_ACCOUNT') }}</h2>
      <!--h2 class="has-center">{{ $t('MY_POTENCIE_ACCOUNT') }}</h2-->

      <hr class="module-separator" style="height: 0px; margin-bottom: 0" />

      <div
        v-if="alert.message"
        class="notification is-danger"
        style="margin-bottom: 2rem"
      >{{ alert.message }}</div>

      <form @submit.prevent="signup">
        <p-control :label="$t('ENTER_EMAIL')">
          <p-input
            type="email"
            v-model="register.t_email"
            :placeholder="$t('ENTER_EMAIL_EX')"
            :disabled="loading"
          ></p-input>
        </p-control>

        <p-control :label="$t('CREATE_NICKNAME')">
          <p-input
            :placeholder="$t('CREATE_NICKNAME_EX')"
            v-mask="'[a-zA-Z]{1}[a-zA-Z0-9._-]{0,31}'"
            v-model="register.t_nickname"
            :disabled="loading"
          ></p-input>
        </p-control>

        <div class="form-options">
          <p>
            Al registrarte confirmas que aceptas el
            <a
              href="#"
              class="is-link"
            >{{ $t('LICENSE_AGREEMENT') }}</a> y la
            <a href="#">{{ $t('PRIVACY_POLICY') }}.</a>
          </p>
        </div>

        <div>
          <button
            class="button is-brand is-expand"
            :class="{ 'is-loading': loading }"
            :disabled="inValid"
          >{{ $t('CREATE_ACCOUNT') }}</button>
          <p class="has-text-danger has-center" v-if="inValid">{{ validMessage }}</p>
        </div>
      </form>
      <p class="has-center">
        <span>
          <router-link :to="{ name: 'home' }">
            {{
            $t('ALREADY_HAVE_POTENCIE_ACCOUNT')
            }}
          </router-link>
        </span>
      </p>
    </div>
  </section>
</template>

<script>
const REG_FAMILY = /^\b([Ff][Aa][Mm][Ii][Ll][Yy]\.)/;

export default {
  name: 'SignUp',
  data() {
    return {
      loading: false,
      register: {
        t_nickname: '',
        t_email: '',
        token_key: process.env.VUE_APP_STATIC_TOKEN,
        code_language: 'ES',
        code_country: 'PE',
      },
      alert: {
        type: '',
        message: null,
        show: true,
      },
    };
  },
  computed: {
    inValid() {
      return REG_FAMILY.test(this.register.t_nickname);
    },
    validMessage() {
      return this.inValid ? 'Los entorno no pueden iniciar con "family."' : '';
    },
  },
  methods: {
    signup() {
      if (this.register['t_nickname'].length < 3) {
        this.$toast.open({
          message: 'Se necesitan un nickname de por lo menos 3 dígitos',
          type: 'is-danger',
        });
        return;
      }

      this.loading = true;
      this.alert.message = '';

      this.$callApi
        .post(process.env.VUE_APP_AUTO_REGISTER, this.register)
        .then(res => {
          this.loading = false;
          if (res.data) {
            if (res.data.data) {
              //console.log('t_nickname:', res.data.data.t_nickname);
              this.$router.replace({
                name: 'home',
                query: { nickname: res.data.data.t_nickname },
              });
            } else {
              let msg = res.data.message;

              if (msg == 'error_bad_request:Nickname or email already exist') {
                this.alert.message =
                  'El nickname o email ya existen. Prueba con otro.';
              }
            }
          }
        })
        .catch(() => {
          //console.error('Error en el SignUp:', err);
          this.loading = false;
          this.alert.message =
            'No se ha podido crear el usuario, porfavor intente nuevamente';
        });
    },
  },
  mounted() {
    /*let language = window.navigator.language || navigator.browserLanguage;
    //console.log('lang', language);
    let d = language.split('-');
    //console.log('lang', d);
    //this.register['code_language'] = d[1];*/
  },
};
</script>
