<template>
  <section class="card transparent-card">
    <div class="card-content">
      <h2 class="has-center">{{ $t('WELCOME') }}</h2>

      <hr class="module-separator" style="height: 0px; margin-bottom: 0" />

      <div
        v-if="alert.message"
        :class="['notification', alert.type]"
        style="margin-bottom: 2rem"
      >{{ alert.message }}</div>

      <form method="POST" :action="url" autocomplete="off">
        <p-control :label="$t('USER_MAIL')">
          <p-input
            v-model="nickname"
            name="t_nickname"
            requiredMessage="Este campo es obligatorio"
            required
          />
        </p-control>
        <p-control :label="$t('PASSWORD')">
          <p-input
            v-model="password"
            type="password"
            name="t_password"
            requiredMessage="Este campo es obligatorio"
            required
          />
        </p-control>
        <input name="token_key" value="b6439b0803df6ae225d9d1ed04a5530a" type="hidden" />

        <div class="from-options">
          <!--p-checkbox>Recordarme</p-checkbox-->
          <div></div>
          <router-link :to="{ name: 'recoverypassword' }">
            {{
            $t('PASSWORD_FORGOT')
            }}
          </router-link>
        </div>

        <button @click="postPrevent" class="button is-brand is-expand">{{ $t('ENTER') }}</button>
      </form>

      <p class="has-center">
        <router-link :to="{ name: 'signup' }">
          {{
          $t('CREATE_ACCOUNT_HERE')
          }}
        </router-link>
      </p>
    </div>
  </section>
</template>

<script>
const URL_POST = process.env.VUE_APP_URL_POST;

export default {
  name: 'Home',
  data() {
    return {
      nickname: '',
      password: '',
      alert: {
        type: '',
        message: null,
        show: true,
      },
      url: URL_POST,
    };
  },
  computed: {
    isFormActive() {
      return this.nickname != '' && this.password != '';
    },
  },
  methods: {
    postPrevent() {
      /*fetch(URL_POST, {
        method: 'POST',
      });*/
    },
  },
  created() {
    let p = this.$route.query;

    if (p.res) {
      let message = '';

      if (p.res == 'unauthorized_fail_credentials') {
        message = 'Usuario o contraseña incorrectos';
      } else if (p.res == 'unauthorized_client_not_exist') {
        message = 'El usuario no existe';
      } else {
        message = p.res;
      }
      this.alert.type = 'is-warning';
      this.alert.message = message;
    }

    if (p.nickname) {
      this.alert.message =
        'Hemos creado tu usuario y te hemos enviado la contraseña a tu correo.';
      this.alert.type = 'is-success';
      this.nickname = p.nickname;
      this.newnickname = true;
    }

    if (p.newpassword) {
      this.alert.message =
        'La contraseña se cambio correctamente. Ya puedes ingresar a la plataforma';
      this.alert.type = 'is-success';
    }
  },
  mounted() {
    let firstInput = this.$el.querySelectorAll('input');

    if (this.newnickname) {
      firstInput[1].focus();
      return;
    }

    if (firstInput[0]) {
      firstInput[0].focus();
    }
  },
};
</script>
