<template>
  <section class="card transparent-card">
    <div class="card-content">
      <h2 class="has-center">{{ $t('RECOVER_PASSWORD') }}</h2>

      <div
        v-if="alert.message"
        :class="['notification', alert.type]"
        style="margin-bottom: 2rem"
      >{{ alert.message }}</div>

      <form @submit.prevent="recoveryaccount">
        <p-control :label="$t('RECOVER_PASSWORD_NOTIFICATION')">
          <p-input type="email" v-model="email" :disabled="loading"></p-input>
        </p-control>

        <div>
          <button
            class="button is-brand is-expand"
            :class="{ 'is-loading': loading }"
          >{{ $t('SEND') }}</button>
        </div>
      </form>
      <p class="has-center">
        <router-link :to="{ name: 'home' }">{{ $t('LOGIN') }}</router-link>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RecoveryPassword',
  data() {
    return {
      loading: false,
      email: '',
      alert: {
        type: '',
        message: null,
      },
    };
  },
  methods: {
    recoveryaccount() {
      this.loading = true;
      this.alert.message = null;

      this.$callApi
        .post(process.env.VUE_APP_CHANGE_PASSWORD, {
          t_email: this.email,
          mailpage: 'bfcbe8846e1ca8ddde4b739ead308faa',
        })
        .then(r => {
          //console.log('Reponse: ', r);
          this.loading = false;
          if (r.data) {
            let message = r.data.message;

            let msg = message.split(':');

            //console.log('mensages: ', msg);

            this.alert.type = 'is-' + msg[0];

            if (msg[1] == 'An email has been sent to your email successfully') {
              this.alert.message =
                'Un email ha sido enviado a tu correo correctamente';
            } else {
              this.alert.message = msg[1];
            }
          }
        })
        .catch(() => {
          this.loading = false;
          //console.error('RecoveryPassword', e);
          this.alert.type = 'is-danger';
          this.alert.message = 'No se puede recuperar la contraseña';
        });
    },
  },
};
</script>
