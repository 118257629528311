import Vue from 'vue';
import App from './App.vue';
import { Potencie } from 'potencie-core';
import router from './router';

Vue.config.productionTip = false;
Vue.use(Potencie, {
  language: 'https://potencie.com/i18n/language_dictionary/',
  errorLanguage(a) {
    console.error(a);
  },
});

new Vue({
  render: h => h(App),
  router,
}).$mount('#app');
