<template>
  <section class="card transparent-card">
    <div class="card-content">
      <h2>Cambia de contraseña</h2>

      <div
        v-if="alert.message"
        :class="['notification', alert.type]"
        style="margin-bottom: 2rem"
      >{{ alert.message }}</div>

      <form @submit.prevent="passwordChange">
        <p-control label="Nueva Contraseña">
          <p-input v-model="newPassword" type="password"></p-input>
        </p-control>

        <p-control label="Repita la nueva contraseña">
          <p-input v-model="repeatPassword" type="password"></p-input>
        </p-control>

        <p v-if="showMessage">Las contraseñas deben ser iguales</p>

        <div>
          <button
            class="button is-brand is-expand"
            :class="{ 'is-loading': loading }"
            :disabled="token == ''"
          >Cambiar contraseña</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ChangePassword',
  data() {
    return {
      loading: false,
      newPassword: '',
      repeatPassword: '',
      token: '',
      alert: {
        type: '',
        message: null,
      },
    };
  },
  computed: {
    validPassword() {
      return this.repeatPassword === this.newPassword;
    },
    showMessage() {
      return (
        !this.validPassword &&
        this.repeatPassword !== '' &&
        this.newPassword !== ''
      );
    },
  },
  methods: {
    passwordChange() {
      if (this.validPassword) {
        if (this.newPassword.length < 8) {
          this.$toast.open({
            message: 'Las contraseñas debe tener como mínimo 8 caracteres.',
            type: 'is-danger',
          });
          return;
        }

        this.loading = true;
        this.alert.message = '';
        this.$callApi
          .post(
            process.env.VUE_APP_RESET_PASSWORD,
            {
              t_password: this.newPassword,
            },
            { headers: { Authorization: 'Bearer ' + this.token } },
          )
          .then(r => {
            this.loading = false;

            if (r.data) {
              if (r.data.code == 'success') {
                //this.alert.type = 'is-success';
                this.$router.replace({
                  name: 'home',
                  query: { newpassword: true },
                });
              } else {
                this.alert.type = 'is-danger';
                this.alert.message = 'No se pudo cambiar la contraseña';
              }
            }
          })
          .catch(() => {
            //console.error('Error change Password', e);
            this.loading = false;
            this.alert.type = 'is-danger';
            this.alert.message = 'No se pudo cambiar la contraseña';
          });
      } else {
        this.$toast.open({
          message: 'Para poder continuar las contraseñas deben coincidir',
          type: 'is-danger',
        });
      }
    },
  },
  created() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
    }
  },
};
</script>
