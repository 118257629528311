import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Home from '@/views/Home';
import SignUp from '@/views/SignUp';
import RecoveryPassword from '@/views/RecoveryPassword';
import ChangePassword from '@/views/ChangePassword';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/sign_up',
    name: 'signup',
    component: SignUp,
  },
  {
    path: '/recovery_password',
    name: 'recoverypassword',
    component: RecoveryPassword,
  },
  {
    path: '/new_password',
    name: 'changepassword',
    component: ChangePassword,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'router-active',
  linkExactActiveClass: 'router-exact',
  routes,
});

export default router;
